<template>
  <div id="app">
    <PayClient/>
  </div>
</template>

<script>
import PayClient from "@/components/PayClient.vue";
export default {
  name: 'App',
  components: {
    PayClient
  }
}
</script>